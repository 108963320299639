import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Image from "react-bootstrap/Image";
import Badge from "react-bootstrap/Badge";
import Nav from "react-bootstrap/Nav";

import { createElement } from 'react';


import StFooter from "../components/StFooter";
import StNavbar from "../components/StNavbar";
import { useParams } from "react-router-dom";

import { BlogsConfig } from './Blogs/BlogsConfig';
import { BlogList } from './Blogs/BlogsConfig';
import { BodyStyle } from './Blogs/BlogsConfig';

const Tdebug = false;

const StBlog = () => {

    const { blogName } = useParams();

    Tdebug && console.log(blogName);

    if (!(blogName in BlogList)) {
        return (
            <div>
                Blog not found!
            </div>
        )
    }

    const returnTag = (tagName) => {
        switch (tagName) {
            case "p": return "p";
            case "newLine": return "br";
            case "image": return "Image";
            case "subTitle": return "p";
            case "pre": return "pre";

            default: return "div";
        }

    }

    const escapeHtml = (unsafe) => {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }

    const procAst = (ast) => {

        function procHeading(h) {

            Tdebug && console.log("<h" + h.level + ">");
            genHtml += "<h" + h.level + ">";

            switch(h.children[0].type) {
            case "text":
                    Tdebug && console.log(h.children[0].value);
                    genHtml += h.children[0].value;
                    break;
    
            default:
                    break;
            }
            Tdebug && console.log("</h" + h.level + ">");
            genHtml += "</h" + h.level + ">";
        }

        function procList(uol, listType) {

            Tdebug && console.log((listType == "ordered"? "<ol>":"<ul>"));
            genHtml += (listType == "ordered"? "<ol>":"<ul>");
    
            for (let oo = 0; oo < uol.children.length; oo++) {
    
                    let olchild = uol.children[oo];
    
                    switch(olchild.type) {
                    case "text":
                            Tdebug && console.log(olchild.value);
                            genHtml += olchild.value;

                            break;
    
                    case "codeBlock":
                            Tdebug && console.log("<pre class=" + BodyStyle["pre"] + ">");
                            genHtml += "<pre class=" + BodyStyle["pre"] + ">";
    
                            // Escape HTML elements.
                            let html = olchild.value.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', '&quot;').replaceAll("'", '&#039;');
    
                            Tdebug && console.log(html);
                            genHtml += html 

                            Tdebug && console.log("</pre>");
                            genHtml += "</pre>";

                            break;
    
                    case "link":
                            Tdebug && console.log("<a href=" + olchild.url + ">");
                            genHtml += "<a href=" + olchild.url + ">";

                            Tdebug && console.log(olchild.text);
                            genHtml += olchild.text;

                            Tdebug && console.log("</a>");
                            genHtml += "</a>";

                            break;
    
                    case "listItem":
                            for (let ll = 0; ll < olchild.children.length; ll++) {
                                    Tdebug && console.log("<li>" + olchild.children[ll].value + "</li>");
                                    genHtml += "<li>" + olchild.children[ll].value + "</li>";

                            }
    
                    default:
                            break;
                    }
            }
    
    
            Tdebug && console.log((listType == "ordered"? "</ol>":"</ul>"));
            genHtml += (listType == "ordered"? "</ol>":"</ul>");
    
        }
    
        function procPara(p) {

            Tdebug && console.log("<p>");
            genHtml += "<p class=" + BodyStyle["p"]  + ">";
    
            for (let jj = 0; jj < p.children.length; jj++) {
    
                    let pchild = p.children[jj];
    
                    switch(pchild.type) {
                    case "text":
                            Tdebug && console.log(pchild.value);
                            genHtml += pchild.value;
                            break;
    
                    case "codeBlock":
                            Tdebug && console.log("<pre>");
                            genHtml += "<pre class=" + BodyStyle.pre + ">";
    
                            // Escape HTML elements.
                            let html = pchild.value.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', '&quot;').replaceAll("'", '&#039;');
    
                            Tdebug && console.log(html);
                            genHtml += html;

                            Tdebug && console.log("</pre>");
                            genHtml += "</pre>"
                            break;
    
                    case "link":
                            Tdebug && console.log("<a href=" + pchild.url + ">");

                            genHtml += "<a href=" + pchild.url + ">";

                            Tdebug && console.log(pchild.text);
                            genHtml += pchild.text;

                            Tdebug && console.log("</a>");
                            genHtml += "</a>"

                            break;
                    
                    case "image":
                            Tdebug && console.log("<img src=" + pchild.url + " alt=" + pchild.text + " />");
                            genHtml += "<img src=" + pchild.url + " alt=" + pchild.text + " />";                
                            break;

                    default:
                            break;
                    }
    
            }
            Tdebug && console.log("</p>");
            genHtml += "</p>";
        }
    
    
        let genHtml = '';

        for (let ii = 0; ii < ast.children.length; ii++) {

            let child = ast.children[ii];
    
            switch (child.type) {
                    case "paragraph":
                            procPara(child);
                            break;
    
                    case "heading":
                            procHeading(child);
                            break;
    
                    case "orderedList":
                            procList(child, "ordered");
                            break;
    
                    case "unorderedList":
                            procList(child, "unordered");
                            break;
    
                    default:
                            break;
    
            }
        }

        return genHtml;
    
    
    }
        
    Tdebug && console.log(blogName);
    Tdebug && console.log(BlogList[blogName]);

    const ttl = document.querySelector('title');
    ttl.innerText = BlogList[blogName].blogHeader.blogTitle; 

    const mta = document.querySelector("meta[name='description']");
    mta.setAttribute('content', BlogList[blogName].blogHeader.blogSubTitle);

    const blogBody = BlogList[blogName].blogBody;
    let generatedHtml = [];
    let ast = undefined;
    let inHtml = '';

    Tdebug && console.log(blogBody);

    Tdebug && console.log(BodyStyle);

    Tdebug && console.log(BlogList[blogName].blogHeader.blogType);

    if (BlogList[blogName].blogHeader.blogType == 'innerHTML') {

        ast = BlogList[blogName].blogBody;

        inHtml = procAst(ast);
    }
    else {
            
        for (let ii = 0; ii < blogBody.length; ii++) {

            switch(blogBody[ii].tagType) {
                case "p":
                    Tdebug && console.log("received <p>");
                    //generatedHtml[ii] = "<p className='"+ BodyStyle["p"] + "'>" + blogBody[ii].tagContent + "</p>";
                    generatedHtml[ii] = {
                        "content": blogBody[ii].tagContent,
                        "tag": "p",
                        "style": BodyStyle["p"],
                        "additionalTagParams": "",
                        "noClose": false
                    }
                    // generatedHtml[ii].tag = "p";
                    // generatedHtml[ii].style = BodyStyle["p"];
                    break;

                case "image":
                    Tdebug && console.log("received <image>");
                    generatedHtml[ii] = {
                        "tag": "Image",
                        "style": BodyStyle["image"], //+ " src=" + blogBody[ii].tagContent,
                        "additionalTagParams": "src = " + blogBody[ii].tagContent,
                        "content": "/" + blogBody[ii].tagContent,
                        "noClose": true,
                        "altText": blogBody[ii].altText
                    }
                    break;

                case "newLine":
                    Tdebug && console.log("received newline");
                    generatedHtml[ii] = {
                        "tag" : "br",
                        "style": "",
                        "content": "",
                        "noClose": true
                    }
                    break;

                case "subTitle":
                    Tdebug && console.log("received title");
                    //generatedHtml[ii] = "<p className='" + BodyStyle["subTitle"] + "'>" + blogBody[ii].tagContent + "</p>"
                    //Tdebug && console.log(generatedHtml[ii]);

                    generatedHtml[ii] = {
                        "tag": "p",
                        "style": BodyStyle["subTitle"],
                        "content": blogBody[ii].tagContent,
                        "noClose": false
                    }
                    break;
                case "pre":
                    Tdebug && console.log("received pre");
                    Tdebug && console.log(blogBody[ii].tagContent);
                    //console.log(blogBody[ii].tagContent.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t"));
                    generatedHtml[ii] = {
                        //"content": blogBody[ii].tagContent.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t"),
                        //"content": blogBody[ii].tagContent,
                        "content": blogBody[ii].tagContent.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', '&quot;').replaceAll("'", '&#039;'),
                        "tag" : "pre",
                        "style": BodyStyle["pre"],
                        "noClose": false
                    }

                    Tdebug && console.log(generatedHtml[ii]);
                    
                    break;


                default:
                    Tdebug && console.log("default");
                    break;

            }

        }



        Tdebug && console.log("FULL HTML IS: ");
        Tdebug && console.log(generatedHtml);

    }

  

    return (
                <div>
                    
                        <StNavbar />
                        <div className='d-flex flex-col container-lg'>
                                <Container className="">
                                        <div className='mt-0 mb-5 minitron-blog'>
                                                <Container className='flex-lg-row align-items-center'>
                                                        <Row className=''>
                                                                <Col className="minitron-blog-header">
                                                                        <div className="h1 mx-5">{BlogList[blogName].blogHeader.blogTitle}</div>
                                                                        <div className="h2 mx-5">{BlogList[blogName].blogHeader.blogSubTitle}</div>
                                                                        <p></p>

                                                                        <Badge className="mx-5" bg="primary">{BlogList[blogName].blogHeader.blogCategofy}</Badge>

                                                                        <Container className="flex-col mx-5 px-0">

                                                                                <div>
                                                                                        <p className="my-3 mr-5">
                                                                                            Author: {BlogList[blogName].blogHeader.blogWriter}
                                                                                        </p>
                                                                                </div>        
                                                                                <div>
                                                                                    <p className="my-3">
                                                                                        Published: {BlogList[blogName].blogHeader.blogDate}
                                                                                    </p>

                                                                                </div>

                                                                        </Container>


                                                                </Col>
                                                        </Row>

                                                </Container>

                                        </div>


                                        <div>
                                            <Container className="my-4">
                                             
                                                {(ast !== undefined)?
                                                    //dangerouslySetInnerHTML(_html: inHtml ) :
                                                 ( <div dangerouslySetInnerHTML = {{__html: inHtml}}></div> ):
                                                 //<div dangerouslySetInnerHTML{{__html: '<p>First &middot; Second </p>'}}> </div>
                                                //<div dangerouslySetInnerHTML={{__html: '<p>First &middot; Second</p>'}}></div> :

                                                generatedHtml.map((item) => {
                                                    Tdebug && console.log(item);
                                                    //return <div dangerouslySetInnerHTML={{__html: item}} />
                                                    //return <item.tag className={item.style} dangerouslySetInnerHTML={{__html: item.content}}></item.tag>
                                                    //const jscx = 

                                                    /* If more components are needed, use this:
                                                     * Basically, the createElement cannot be a string for React components.
                                                    const components  = {
                                                        "Image": Image
                                                    }
                                                        */


                                                    if (item.tag == "Image") {
                                                        Tdebug && console.log("returning image jsx");
                                                        //return <TagName key={Math.random()} className={item.style} src={item.content} />;
                                                        //return createElement(Image, { key: Math.random(), className: item.style, src: item.content, alt: item.altText });
                                                        return <Image key={Math.random()} className={item.style} src={item.content} alt={item.altText} fluid />
                                                    }
                                                    else if (item.tag == "br") {
                                                        Tdebug && console.log("returning br jsx");
                                                        return <item.tag key={Math.random()} />
                                                        //<div key={Math.random()} className='new-line' ></div>
                                                    }
                                                    else {
                                                        return (
                                                            <item.tag key={Math.random()} className={item.style} dangerouslySetInnerHTML={{__html: item.content}}></item.tag>
                                                        );
                                                    }

                                                    //(item.noClose == false)? (return <item.tag key={Math.random()} className={item.style} dangerouslySetInnerHTML={{__html: item.content}}></item.tag> ):
                                                    //return '';

                                                })
                                                }

                                                {/*
                                                {blogBody.map((item) => {
                                                    //return <item.tagType className={BodyStyle[item.tagType]}>{dangerouslySetInnerHTML(item.tagContent)}</item.tagType>
                                                    return <item.tagType className={BodyStyle[item.tagType]} dangerouslySetInnerHTML={{__html: item.tagContent}}></item.tagType> 
                                                })


                                                }
                                                */}

                                            </Container>

                                        </div>

{/*
                                        <div>

                                                <Container className="my-4">
                                                        <p className="">
                                                                The World Wide Web is one of the most accessible platforms to connect with the world. Whether you're a professional, a student, or someone with a creative passion, the web offers limitless opportunities to share your story, ideas, or portfolio.


                                                                Yet for many, having a permanent online presence through a personal website remains a dream. Why? Common obstacles include complexity, lack of technical know-how, financial costs, limited time, and sheer effort.

                                                        </p>
                                                        <p>
                                                                At TurboSteed, our mission is to eliminate these barriers. We’ve designed a straightforward and user-friendly path to help you host your website in the easiest way possible.

                                                                If you’re familiar with basic internet navigation and desktop tools, you’re ready to follow the steps below and have your website live in no time.
                                                        </p>

                                                        <br />
                                                        <p className="h4">
                                                        Step 1: Create a TurboSteed account
                                                </p>
                                                <p>
                                                        To get started, visit <a href="https://www.turbosteed.com">turbosteed.com</a> and create your account. Simply click the <a href="dashboard.turbosteed.com">Login</a> button and sign in using your Google account. Once logged in, you’ll be directed to your dashboard, the central hub for managing your website.
                                                </p>



                                                <br />


                                                <p className="h4">
                                                        Step 2: Choose a Site name
                                                </p>
                                                <p>
                                                        Your site name (technically referred to as a subdomain) is how your website will be identified on TurboSteed. Choose a name that reflects your website's purpose or personal branding.

                                                        For example, if your name is John Doe, you might name your site ‘john’, resulting in a URL like ‘john.turbosteed.com’. Feel free to get creative—the name is entirely up to you!
                                                </p>
                                                <p>

                                                        In this tutorial, we choose the name ‘Ethan Thompson’ as an example who is building a developer portfolio. Hence, we have named this site ‘ethan-thompson', resulting in the address ‘ethan-thompson.turbosteed.com’. After selecting your site name, click the Update button to save your choice.
                                                </p>

                                                <Image classname="my-4 p-4" src="darksitename.svg" />

                                                <br />
                                                <br />

                                                <p className="">
                                                        You can purchase a domain name and link it with your turbosteed account to make your website all professional. Please contact <a href="mailto:support@turbosteed.com">support</a> to assist you in doing so.
                                                </p>

                                                <br />

                                                <p className="h4">
                                                        Step 3: Upload your static website
                                                </p>
                                                <p>
                                                        You can either create a static website from scratch or choose from thousands of popular templates available online. (Some references include <a href="https://designmodo.com/website-templates/">Designmodo</a>, <a href="https://themeforest.net/search/static?compatible_with=Bootstrap&sort=price-asc#content">ThemeForest</a>, and similar platforms.) Many templates are free, while more advanced options are available for purchase.

                                                        Once you’ve built your website or customized a template, compress the folder containing your website’s code into a ZIP file. Ensure the folder includes a file named index.html in the root directory, as this serves as the entry point for your site.

                                                        For simplicity, you can <a href="https://github.com/turbosteed/static-site/archive/refs/heads/main.zip">download</a> a static website on our GitHub page. (Don’t worry—you can always update the content later with your own).

                                                </p>

                                                <p>
                                                        On your TurboSteed dashboard, navigate to the page where you entered your site name. Below the screenshot preview, click the Choose File button and upload your .zip file.
                                                </p>


                                                <Image classname="my-4 p-4" src="darksiteupload.svg" />

                                                <br />
                                                <br />

                                                <p>
                                                        Once the upload is complete, your website will go live instantly. The screenshot will update to show a preview of your new website. For this demo, the webpage will display the sample content we provided.
                                                </p>
                                                <br />

                                                <Image classname="my-4 p-4" src="darksitedemo.png" />
                                                <br />
                                                <br />

                                                <br />

                                                        <p className="h4">
                                                                Step 4: Share your website
                                                        </p>
                                                        <p>
                                                                Congratulations! Your website is now live and accessible at ‘your-site-name.turbosteed.com’. Share your link with friends, colleagues, or anyone you want to impress.

                                                                With TurboSteed, creating an online presence has never been easier. Whether you’re showcasing a portfolio, starting a blog, or building something unique, we’re here to help you make it happen.
                                                        </p>

                                                        <p>

                                                                <a href="https://dashboard.turbosteed.com">Start hosting</a> today and unlock your web potential!
                                                        </p>

                                                </Container>

                                        </div>

*/}
                                </Container>
                        </div>
                        <StFooter />

                </div>


    );

}

export default StBlog;

